import { Button } from "@chakra-ui/button";
import { Flex, Spacer, VStack, Heading, Box, Divider } from "@chakra-ui/layout";
import { ModalContent } from "@chakra-ui/modal";
import { ModalCloseButton } from "@chakra-ui/modal";
import { ModalFooter } from "@chakra-ui/modal";
import { ModalBody } from "@chakra-ui/modal";
import { ModalHeader } from "@chakra-ui/modal";
import { ModalOverlay } from "@chakra-ui/modal";
import { Modal } from "@chakra-ui/modal";
import { Image } from "@chakra-ui/react";

export default function ConnectDatabaseModal({ isOpen, onClose }) {
    return (
        <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>How to connect a database</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <VStack spacing={6} width="100%">
                        <Flex width="100%">
                            <Box w="13.125rem" minH="5.625rem">
                                <Heading size="xs">1. Go to your database page and click Share.</Heading>
                            </Box>
                            <Spacer />
                            <Box w="19rem" minH="5.625rem">
                                <Image w="306px" h="92px" src="https://thegist-public2.s3.amazonaws.com/app/new-rule-help-step1.png" />
                            </Box>
                        </Flex>
                        <Divider />
                        <Flex width="100%">
                            <Box w="13.125rem" minH="5.625rem">
                                <Heading size="xs">2. Share the page with the:gist.</Heading>
                            </Box>
                            <Spacer />
                            <Box w="19rem" minH="5.625rem">
                                <Image w="306px" h="135px" src="https://thegist-public2.s3.amazonaws.com/app/new-rule-help-step2.png" />
                            </Box>
                        </Flex>
                        <Divider />
                        <Flex width="100%">
                            <Box w="13.125rem" minH="5.625rem">
                                <Heading size="xs">3. Your database is now available in the:gist!</Heading>
                            </Box>
                            <Spacer />
                            <Box w="19rem" minH="5.625rem">
                                <Image w="306px" h="149px" src="https://thegist-public2.s3.amazonaws.com/app/new-rule-help-step3.png" />
                            </Box>
                        </Flex>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="pink" onClick={onClose} w={24}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}