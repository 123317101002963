import {
    Box,
        Button,
        FormControl,
        FormErrorMessage,
        FormLabel,
        HStack,
        Input,
        Stack,
    } from '@chakra-ui/react'
import { useState } from 'react';
import { registerUser } from '../../services/HttpClient';
    
export const SignupForm = (props) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const [emailErrors, setEmailErrors] = useState([]);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [generalError, setGeneralError] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setEmailErrors([])
        setPasswordErrors([])
        setGeneralError(undefined)

        registerUser(firstName, lastName, email, password).then(resp => {
            window.location = resp.data.location
        }).catch((error) => {
            setIsLoading(false)
            if (error.response.status === 400) {
                setEmailErrors(error.response.data.form.fields.email.errors);
                setPasswordErrors(error.response.data.form.fields.password1.errors);
            } else {
                setGeneralError("Got an unexpected error, please try again or contact us")
            }
        })
    }

    const emailFormErrors = emailErrors.map(error => <FormErrorMessage key={error}>{error}</FormErrorMessage>)
    const passwordFormErrors = passwordErrors.map(error => <FormErrorMessage key={error}>{error}</FormErrorMessage>)

    return (
    <form
        onSubmit={onSubmit}
    >
        <Stack spacing="6">
            <HStack spacing={5}>
                <FormControl id="first_name">
                    <FormLabel>First name</FormLabel>
                    <Input name="first_name" onChange={e => setFirstName(e.target.value)} value={firstName} required />
                </FormControl>
                <FormControl id="last_name">
                    <FormLabel>Last name</FormLabel>
                    <Input name="last_name" onChange={e => setLastName(e.target.value)} value={lastName} required />
                </FormControl>
            </HStack>
            <FormControl id="email" isInvalid={emailErrors.length}>
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={e => setEmail(e.target.value)} name="email" type="email" autoComplete="email" required />
                {emailFormErrors}
            </FormControl>
            <FormControl id="password" isInvalid={passwordErrors.length}>
                <FormLabel>Password</FormLabel>
                <Input value={password} onChange={e => setPassword(e.target.value)} name="password" type="password" required />
                {passwordFormErrors}
            </FormControl>
            {generalError ? <Box color="red" fontSize="sm">{generalError}</Box> : <></>}
            <Button isLoading={isLoading} type="submit" colorScheme="pink" size="lg" fontSize="md"
                disabled={(!firstName || !lastName || !email || !password) || isLoading}
            >
                Create my account
            </Button>
        </Stack>
    </form>
    )}