import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { FaGoogle } from "react-icons/fa"

export default function ContinueWithGoogle() {
    return (
        <Button variant="outline" w="100%" leftIcon={<Icon as={FaGoogle} color="#EB4335" />}
            color="gray.700"
        >
            Continue with Google
        </Button>
    )
}