import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Heading } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Container } from "@chakra-ui/layout";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { resetPasswordSetNewPassword } from "../../services/HttpClient";
import { useEffect } from "react";

export default function ResetPasswordSetPasswordContainer() {
    let { id } = useParams();
    let history = useHistory();

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);

    const [password1Errors, setPassword1Errors] = useState([]);
    const [password2Errors, setPassword2Errors] = useState([]);
    const [generalErrors, setGeneralErrors] = useState([]);

    useEffect(() => {
        window.analytics.page('/reset-password/set/');
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setPassword1Errors([])
        setPassword2Errors([])
        setGeneralErrors([])

        resetPasswordSetNewPassword(id, password1, password2).then(resp => {
            setIsSuccess(true);
            setTimeout(() => {
                history.replace('/login')
            }, 3000)
        }).catch(error => {
            setIsLoading(false)
            const data = error.response.data;
            if (error.response.status === 400) {
                if (data.form.fields.password1) {
                    setPassword1Errors(data.form.fields.password1.errors);
                }
                if (data.form.fields.password2) {
                    setPassword2Errors(data.form.fields.password2.errors);
                }
                if (data.form.errors) {
                    setGeneralErrors(data.form.errors)
                }
            } else {
                setGeneralErrors(["Got an unexpected error. Please try again or contact us using the chat below, or at support@thegist.so"])
            }
        })
    }

    const password1FormErrors = password1Errors.map(error => <FormErrorMessage key={error}>{error}</FormErrorMessage>)
    const password2FormErrors = password2Errors.map(error => <FormErrorMessage key={error}>{error}</FormErrorMessage>)
    const generalErrorsBox = generalErrors.map(error => <Box color="red" fontSize="sm">{error}</Box>)

    return (
        <Container bg="white" mt={10} maxW="lg" p={8}>
            {isSuccess ?
            <>
                <Center><Heading as="h1" mb={6} textAlign="center">Your password has been set successfully</Heading></Center>
                <Center fontSize="sm">We're redirecting you to the login page shortly...</Center>
            </>
            :
            <>
                <Center><Heading as="h1" mb={6} textAlign="center">Enter your new password</Heading></Center>
                <form onSubmit={onSubmit}>
                    <Stack spacing={6}>
                        <FormControl id="password1" isInvalid={password1Errors.length}>
                            <Input 
                                value={password1} 
                                onChange={e => setPassword1(e.target.value)} 
                                name="password1" type="password" required placeholder="Password" />
                            {password1FormErrors}
                        </FormControl>
                        <FormControl id="password2" isInvalid={password2Errors.length}>
                            <Input 
                                value={password2} 
                                onChange={e => setPassword2(e.target.value)} 
                                name="password2" type="password" required placeholder="Confirm password" />
                            {password2FormErrors}
                        </FormControl>
                        {generalErrorsBox}
                        <Button isLoading={isLoading} type="submit" colorScheme="pink" size="lg" fontSize="md"
                            disabled={!password1 || !password2 || isLoading}
                        >
                            Set my new password
                        </Button>
                    </Stack>
                </form>
            </>
            }
        </Container>
    )
}