import { AlertIcon } from "@chakra-ui/alert";
import { Alert } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Box, Link, Spacer } from "@chakra-ui/layout";
import { useState } from "react";
import { sendEmailVerification } from "../../services/HttpClient";
import { useErrorToast, useSuccessToast } from "../../services/Toaster"

export default function EmailVerificationAlert() {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const errorToast = useErrorToast();
    const successToast = useSuccessToast();

    function onSendVerificationEmailClicked() {
        setIsSubmitting(true);

        sendEmailVerification().then(resp => {
            setIsSubmitting(false);
            successToast("Success", <Box>A verification email was sent to {resp.data.sent}<br />Please check your inbox for further instructions.</Box>)
        }).catch(error => {
            setIsSubmitting(false);
            errorToast('Unexpected error',
                <Box>
                    There was an error while sending the verification email.<br />Please try again or contact us using the chat below, or at <Link href="mailto:support@thegist.so">support@thegist.so</Link>
                </Box>
            )
        })
    }

    return (
        <Alert status="error">
            <AlertIcon />
            You must verify your email before we send you any notifications.
            <Spacer />
            <Button variant="outline" color="gray.700" borderColor="gray.500" isLoading={isSubmitting}
                onClick={e => onSendVerificationEmailClicked()}
            >Resend verification email</Button>
        </Alert>
    )
}