import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Center, Container, Heading, Stack } from "@chakra-ui/layout";
import { useState } from "react";
import { sendResetPasswordEmail } from "../../services/HttpClient";
import { useEffect } from "react";


export default function ResetPasswordContainer() {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [emailErrors, setEmailErrors] = useState([])
    const [emailSent, setEmailSent] = useState(false)

    useEffect(() => {
        window.analytics.page('/reset-password');
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)

        sendResetPasswordEmail(email).then(resp => {
            setEmailErrors([])
            setEmailSent(true)
        }).catch(error => {
            setIsLoading(false)

            if (error.response.status === 400) {
                setEmailErrors(error.response.data.form.fields.email.errors);
            } else {
                setEmailErrors(["Got an unexpected error, please try again or contact us"])
            }
        })
    }

    const emailFormErrors = emailErrors.map(error => <FormErrorMessage key={error}>{error}</FormErrorMessage>)

    return (
        <Container bg="white" mt={10} maxW="lg" p={8}>
            {emailSent ?
                <>
                    <Center><Heading as="h1" mb={6} textAlign="center">Check your inbox for a reset password email.</Heading></Center>
                    <Box textAlign="center" fontSize="sm">Of course, only if we have an account owned by {email} :)<br />If you don't see it in your inbox, check your spam folder.</Box>
                </>
                :
                <>
                    <Center><Heading as="h1" mb={6} textAlign="center">Enter your email to reset password</Heading></Center>
                    <form onSubmit={onSubmit}>
                        <Stack spacing={6}>
                            <FormControl id="email" isInvalid={emailErrors.length}>
                                <Input 
                                    value={email} 
                                    onChange={e => setEmail(e.target.value)} 
                                    name="email" type="email" autoComplete="email" required placeholder="Email" />
                                {emailFormErrors}
                            </FormControl>
                            <Button isLoading={isLoading} type="submit" colorScheme="pink" size="lg" fontSize="md"
                                disabled={!email || isLoading}
                            >
                                Email me a reset link
                            </Button>
                        </Stack>
                    </form>
            </>
            }
        </Container>
    )
}