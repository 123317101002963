import { useEffect, useState } from "react";
import { getUserState } from "./services/HttpClient";
import MainLayout from "./components/MainLayout";
import { identify } from "./services/Segment";

function App() {
  const [userState, setUserState] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    getUserState().then(res => {
      setUserState({isLoggedIn: true, data: res.data})
      identify(res.data.uuid)
    }).catch(error => {
      setUserState({isLoggedIn: false})
      if (error.response.status !== 403) {
        setError('There was an unexpected error reaching our servers, please refresh and try again.')
      }
    })
  }, [])

  if (userState === undefined) {
    return <div>Loading...</div>
  } else if (error) {
    return <div>{error}</div>
  } else {
    return <MainLayout user={userState}/>
  }
}

export default App;
