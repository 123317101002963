import { Button } from "@chakra-ui/button";
import { Link } from "@chakra-ui/layout";
import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger } from "@chakra-ui/popover";
import { EmailIcon, ChatIcon } from "@chakra-ui/icons";

export default function ChatOrEmailUsPopover({ children }) {

    const chatButtonClicked = () => {
        window.$crisp.push(["do", "chat:open"])
    }

    return (
        <Popover
            placement="top-start"
            arrowShadowColor="pink"
        >
            <PopoverTrigger>
                {children}
            </PopoverTrigger>
            <PopoverContent color="gray.800" borderColor="gray.200">
                <PopoverHeader pt={4} fontWeight="bold" border="0">
                    Please contact us
                </PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                    We'd love to hear from you and help with anything you need. We're available on chat and at support@thegist.so.                    
                </PopoverBody>
                <PopoverFooter
                    border="0"
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-end"                    
                    pb={4}
                >
                    <Button colorScheme="pink" leftIcon={<EmailIcon />} variant="outline" as={Link} href="mailto:support@thegist.so" isExternal>Email</Button>
                    <Button colorScheme="pink" leftIcon={<ChatIcon />} marginStart={4} variant="outline" onClick={chatButtonClicked}>Chat</Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}