import { Button } from "@chakra-ui/button";
import { Center, LinkBox } from "@chakra-ui/layout";
import { links } from "../services/HttpClient";
import { Heading } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { LinkOverlay } from "@chakra-ui/layout";

export default function ConnectNotionWorkspace() {
    return <Center mt={24}>
        <VStack spacing={8} textAlign="center">
            <Heading size="xl">Connect your Notion workspace to get started!</Heading>
            <Text>We don’t write in your Notion; we only read what we need to trigger your rules.</Text>
            <LinkBox>
                <LinkOverlay href={links.connectNotion}>
                    <Button variant="solid" colorScheme="pink">Connect with Notion</Button>
                </LinkOverlay>
            </LinkBox>
        </VStack>
    </Center>
}