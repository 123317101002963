import { useToast } from "@chakra-ui/toast"
import { useCallback } from "react"

export function useErrorToast() {
    const toast = useToast()

    const showErrorToast = useCallback((title, description, duration=null) => {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: duration,
            isClosable: true,
            position: "top"
        })
    }, [toast])

    return showErrorToast
}

export function useSuccessToast() {
    const toast = useToast()

    return useCallback((title, description=null) => {
        toast({
            title: title,
            description: description,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top"
        })
    }, [toast])
}

export function useWarningToast() {
    const toast = useToast()

    return useCallback((title, description=null) => {
        toast({
            title: title,
            description: description,
            status: "warning",
            duration: 5000,
            isClosable: true,
            position: "top"
        })
    }, [toast])
}