import { Button } from "@chakra-ui/button";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Container } from "@chakra-ui/layout";
import { Link as RouterLink } from "react-router-dom"
import { useEffect } from "react";

export default function ResetPasswordInvalidTokenContainer() {

    useEffect(() => {
        window.analytics.page('/reset-password/invalid-token/');
    }, [])

    return (
        <Container bg="white" mt={10} maxW="lg" p={8}>
            <Center><Heading as="h1" mb={6} textAlign="center">Invalid link</Heading></Center>
            <Stack spacing={8}>
                <Center fontSize="sm" textAlign="center">Your reset password link has expired or already been used.<br/>Want to try again?</Center>
                <Button as={RouterLink} to="/reset-password" colorScheme="pink">Restart the reset flow</Button>
            </Stack>
        </Container>
    )
}