import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Select } from "@chakra-ui/select"
import { VStack, Center, Box, Heading } from "@chakra-ui/layout"
import { UnorderedList, ListItem } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/spinner"
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/alert"
import { Link } from "@chakra-ui/layout"

export default function CreatePageForm({ formData, formValues, onValuesChange,
  isLoading, errorLoadingForm }) {

  if (isLoading) {
    return <Center w="100%">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="pink.300"
        size="xl"
      />
    </Center>
  }

  if (errorLoadingForm) {
    return <Center w="100%">
      <Alert status="warning">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>Unexpected error</AlertTitle>
          <AlertDescription display="block">
            Please try again. If the problem persists let us know via the chat bubble, or at <Link href="mailto:support@thegist.so" isExternal>support@thegist.so</Link>.
          </AlertDescription>
        </Box>
      </Alert>
    </Center>
  }

  const handleChange = (e) => {
    const prop = formData.find(prop => prop.id === e.target.name)
    const triggerProp = prop.trigger_page_properties.find(triggerProp => triggerProp.id === e.target.value)
    const fixedValueOption = prop.fixed_value_options.find(fixedValueOption => getFixedValueId(prop.property_type, fixedValueOption) === e.target.value)
    const newFormValues = Object.assign({}, formValues)

    if (e.target.value === 'triggerpage') {
      newFormValues[prop.id] = {
        property_name: prop.property_name,
        property_type: prop.property_type,
        value_type: 'trigger_page_id',
      }
    } else if (triggerProp) {
      newFormValues[prop.id] = {
        property_name: prop.property_name,
        property_type: prop.property_type,
        value_type: 'trigger_page_property',
        trigger_page_property_id: triggerProp.id,
        trigger_page_property_name: triggerProp.name
      }
    } else if (fixedValueOption) {
      newFormValues[prop.id] = {
        property_name: prop.property_name,
        property_type: prop.property_type,
        value_type: 'fixed_value',
        fixed_value: getFixedValueToSet(prop.property_type, fixedValueOption)
      }
    } else {
      delete newFormValues[prop.id]
    }

    onValuesChange(newFormValues)
  }

  function getFixedValueToSet(propertyType, fixedValueOption) {
    if (propertyType === 'select') {
      return {'select': {'name': fixedValueOption}}
    } else if (propertyType === 'people') {
      return {'people': [fixedValueOption]}
    }
  }

  function getSelectValueFromFormValues(formProperty) {
    if (formProperty.value_type === 'trigger_page_property') {
      return formProperty.trigger_page_property_id
    } else if (formProperty.value_type === 'fixed_value') {
      if (formProperty.property_type === 'select') {
        return getFixedValueId(formProperty.property_type, formProperty.fixed_value['select']['name'])
      } else if (formProperty.property_type === 'people') {
        return getFixedValueId(formProperty.property_type, formProperty.fixed_value['people'][0])
      }
    } else if (formProperty.value_type === 'trigger_page_id') {
      return 'triggerpage'
    }
  }

  function getFixedValueId(property_type, fixed_value) {
    if (property_type === 'select') {
        return 'select_' + fixed_value
    } else if (property_type === 'people') {
      return 'people_' + fixed_value['id']
    }
  }

  function getFixedValueString(property_type, fixed_value) {
    if (property_type === 'select') {
      return fixed_value
    } else if (property_type === 'people') {
      return fixed_value['name']
    }
  }

  let fields = formData.filter(prop => prop.is_supported).map(prop =>
    <FormControl key={prop.id}>
      <FormLabel>{prop.property_name}</FormLabel>
      <Select
        variant="flushed"
        size="sm"
        focusBorderColor="pink.500"
        name={prop.id}
        onChange={handleChange}
        value={formValues[prop.id] ? getSelectValueFromFormValues(formValues[prop.id]) : ""}
      >
        <option disabled value="" hidden>{
          (prop.trigger_page_properties && prop.trigger_page_properties.length > 0)
            || prop.trigger_page_id
            ? 'Select an option' : 'No properties to copy from'
        }</option>
        {prop.fixed_value_options.length > 0 ?
          <optgroup label="Fixed values">
            {prop.fixed_value_options.map(fixed_value => 
              <option key={getFixedValueId(prop.property_type, fixed_value)} value={getFixedValueId(prop.property_type, fixed_value)}>
                {getFixedValueString(prop.property_type, fixed_value)}
              </option>
              )}
          </optgroup>
        : <></>}
        {prop.trigger_page_properties.length > 0 ?
          <optgroup label="Copy from trigger page">
            {prop.trigger_page_properties.map((triggerProp) =>
              <option key={triggerProp.id} value={triggerProp.id}>{triggerProp.name}</option>
            )}
          </optgroup>
          : <></>
        }
        {
          prop.trigger_page_id ?
            <optgroup label="Link to">
              <option key='triggerpage' value='triggerpage'>Trigger Page</option>
            </optgroup>
            : <></>
        }
        <option value="">Clear</option>
      </Select>
    </FormControl>
  )

  const autogeneratedPropTypes = ['created_time', 'created_by', 'last_edited_time', 'last_edited_by', 'rollup', 'formula']

  let unsupported = formData
    .filter(prop => !prop.is_supported && !autogeneratedPropTypes.includes(prop.property_type))
    .map(prop =>
      <ListItem key={prop.id}>{prop.property_name}</ListItem>
    )

  return (
    <>
      <VStack spacing={4} textAlign="left" alignItems="flex-start">
        {fields}
      </VStack>
      {unsupported.length > 0 ?
        <VStack color="gray.500" marginTop={8} spacing={4} textAlign="left" alignItems="flex-start">
          <Heading as="h5" size="xs">Unsupported properties, coming soon:</Heading>
          <UnorderedList paddingStart={8}>
            {unsupported}
          </UnorderedList>
        </VStack>
        : <></>
      }
    </>
  )
}