import { WarningIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { TagLabel } from "@chakra-ui/tag";
import { TagLeftIcon } from "@chakra-ui/tag";
import { Tag } from "@chakra-ui/tag";

export default function RuleBrokenErrorMessage({ rule, ...props }) {
    return (
        <Box {...props} >
            <HStack>
                <Tag size="lg" variant="solid" colorScheme="red" flexShrink="0">
                    <TagLeftIcon boxSize="12px" as={WarningIcon} />
                    <TagLabel>Error</TagLabel>
                </Tag>
                <Text color="red.400" fontSize="sm">{rule.broken_rule_message}</Text>
            </HStack>
        </Box>
    )
}