import { Select } from "@chakra-ui/select";
import { FormControl } from "@chakra-ui/form-control";

export default function ChooseColumnDropdown(props) {

    const createOptionsFromColumns = () => {
        const supportedOptions = props.columns.filter(
            x => x.is_supported).map(
                x => <option key={x.id} value={x.id}>{x.name}</option>)

        const unSupportedOptions = props.columns.filter(
            x => !x.is_supported).map(
                x => <option disabled key={x.id} value={x.id}>{x.name}</option>)

        const supportedOptGroup = (
            <optgroup label="Supported">
                {supportedOptions}
            </optgroup>
        )

        const unsupportedOptGroup = (
            <optgroup label="Unsupported">
                {unSupportedOptions}
            </optgroup>
        )

        return <>{supportedOptGroup}{unsupportedOptGroup}</>
    }

    const handleChange = (e) => {
        if (e.target.value === "create-column") {
            window.open(props.databaseUrl, '_blank')
        } else {
            props.onChange(e.target.value);
        }
    }

    return (
        <FormControl w="formInputWidth">
            <Select
                disabled={props.disabled || props.isLoading}
                isRequired
                variant="flushed"
                size="sm"
                focusBorderColor="pink.500"
                onChange={handleChange}
                value={props.selectedColumnId}

            >
                <option disabled value="" hidden>{props.isLoading ? "Loading..." : "Select a column"}</option>
                {createOptionsFromColumns()}
                <option value="create-column">+ Create a new column (opens Notion in a new tab)</option>
            </Select>
        </FormControl>
    )
}