import { Container, Flex, HStack, Center, Heading } from "@chakra-ui/layout"
import { ReactComponent as OurLogo } from "../../imgs/app_icon.svg"
import { ReactComponent as NotionLogo } from "../../imgs/notion_logo.svg"
import { Progress } from "@chakra-ui/progress"


function RuleFormLoading() {
  return <Container h="525px" maxW="container.sm" border="1px" borderColor="gray.200" rounded="md" p="12" bg="white" mt="10">
    <Flex flexDirection="column" justifyContent="center" h="100%">
      <Center>
        <HStack spacing={4}>
          <NotionLogo height="80px" width="80px" />
          <Progress w="80px" size="sm" colorScheme="pink" isIndeterminate />
          <OurLogo height="80px" width="80px" />
        </HStack>
      </Center>
      <Center mt={8}>
        <Heading size="lg">Refreshing your Notion databases</Heading>
      </Center>
    </Flex>
  </Container>
}

export default RuleFormLoading