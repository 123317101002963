import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
} from '@chakra-ui/react'
import { useState } from 'react';
import { loginUser } from '../../services/HttpClient';

export default function LoginForm(props) {

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const [formErrors, setFormErrors] = useState([]);

    const onSubmit = (e) => {
        e.preventDefault()
        setFormErrors([])

        loginUser(email, password).then(resp => {
            window.location = resp.data.location
        }).catch(error => {
            if (error.response.status === 400) {
                setFormErrors(error.response.data.form.errors)
            } else {
                setFormErrors(['Got an unexpected error. Please try again or contact us using the chat below, or at support@thegist.so'])
            }
        })
    }

    const formErrorsMessages = formErrors.map(error => <Box color="red" fontSize="sm" key={error}>{error}</Box>)

    return (
    <form
        onSubmit={onSubmit}
    >
        <Stack spacing="6">
            <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={e => setEmail(e.target.value)} name="email" type="email" autoComplete="email" required />
            </FormControl>
            <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input value={password} onChange={e => setPassword(e.target.value)} name="password" type="password" required />
            </FormControl>
            {formErrorsMessages}
            <Button type="submit" colorScheme="pink" size="lg" fontSize="md"
                disabled={!email || !password}
            >
                Log into my account
            </Button>
        </Stack>
    </form>
    )
}