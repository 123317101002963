import { useToast } from "@chakra-ui/toast"
import { useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import { useSuccessToast } from "../../services/Toaster"

export default function URLMessageToastPresenter() {
  const toast = useToast()
  const location = useLocation()
  const history = useHistory()
  const successToast = useSuccessToast();

  useEffect(() => {
    const showErrorToastAndRedirectToHome = (title, description) => {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: null,
            isClosable: true,
            position: "top"
        })
          history.replace('/')
      }
    
    const showSuccessToastAndRedirectToHome = (message) => {
      successToast(message)
      history.replace('/')
    }

    const query = new URLSearchParams(location.search)
    if (query.get("error") === "notion_unknown_error") {
      showErrorToastAndRedirectToHome("Error connecting to Notion", "There was an unexpected error while connecting your Notion account")
    } else if (query.get("error") === "google_unknown_error") {
        showErrorToastAndRedirectToHome("Error connecting to Google", "There was an unexpected error while connecting your Google account")
    } else if (query.get("error") === "email_confirmation_error") {
        showErrorToastAndRedirectToHome("Error confirming email", "This e-mail confirmation link expired or is invalid")
    } else if (query.get("success") === "email_verified") {
        showSuccessToastAndRedirectToHome('Email verified, notifications activated!')
    }
  }, [history, toast, location.search, successToast])

  return ''
}