import { useEffect } from "react";
import { useLocation } from "react-router"
import { page } from "../../services/Segment";

export default function TrackPageViews() {

    const location = useLocation();
    useEffect(() => {
        page(location.pathname)
    }, [location])

    return (
        <></>
    )
}