import { Button } from "@chakra-ui/button";
import { Spacer } from "@chakra-ui/layout";
import { Box, Center, Link } from "@chakra-ui/layout";
import { Heading, HStack } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { finishedCheckout, getPlans } from "../../services/HttpClient";
import { useErrorToast, useSuccessToast } from "../../services/Toaster";
import ChatOrEmailUsPopover from "./ChatOrEmailUsPopover";
import PlanColumn from "./PlanColumn";

export default function PricingPlans({ email, uuid }) {

    const [plans, setPlans] = useState([]);
    const [userPlan, setUserPlan] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();
    const [plansDict, setPlansDict] = useState({})

    useEffect(() => {
        getPlans().then(resp => {
            if (resp.data.paddle_data.is_sandbox) {
                window.Paddle.Environment.set('sandbox');
            }
            
            window.Paddle.Setup({ vendor: resp.data.paddle_data.vendor_id });

            setPlans(resp.data.plans)
            setUserPlan(resp.data.user_plan)
            setIsLoading(false)
            setPlansDict(resp.data.plans.reduce((a,x) => ({...a, [x.plan_id]: x.name}), {}))            
        })
    }, [])

    const showGenericError = () => {
        errorToast("An error has occured", `
                We encountered an error in the payment process. 
                We are looking into this issue. Please feel free to contact us at support@thegist.so`) //TODO copy
    }

    const paddleCheckoutSuccess = (data) => {
        Paddle.Spinner.show()
        finishedCheckout(data.checkout.id).then(resp => {
            Paddle.Spinner.hide()

            if (!resp.data.success) {
                showGenericError()
                return
            }
    
            setUserPlan(resp.data.user_plan)

            const planName = plansDict[resp.data.user_plan.current_plan_id]
            successToast(`Thank you!`, `You've started a monthly subscription of our ${planName} plan.`)
        }).catch(error => {
            Paddle.Spinner.hide()
            showGenericError()
        })
    }

    const Paddle = window.Paddle;
    const openCheckout = (paddleProductId) => {
        Paddle.Checkout.open({ 
            product: paddleProductId,
            email: email,
            disableLogout: true,
            passthrough: JSON.stringify({uuid: uuid}),
            successCallback: paddleCheckoutSuccess
        });
    }

    const currentPlanId = userPlan.current_plan_id
    const isCurrentPlanFree = () => {
        return plans.find(p => p.plan_id === currentPlanId).price_monthly === 0
    }
    const onUpgradeClicked = (plan) => {
        openCheckout(plan.paddle_plan_id);
    }

    const getButtonType = (plan) => {
        const isCurrentPlan = plan.plan_id === currentPlanId;
        if (isCurrentPlan) {
            return "current"
        } else if (isCurrentPlanFree()) {
            return "upgrade"
        } else {
            return "contact_us"
        }
    }

    const paymentMethodUrl = userPlan.change_payment_url

    const getPlanFeatures = (planIndex) => {
        if (planIndex === 0) {
            return ["Emails", "Webhooks"]
        } else if (planIndex <= 2) {
            return ["Emails", "Webhooks", "Faster Triggers"]
        } else {
            return ["Emails", "Webhooks", "Faster Triggers", "Premium Support"]
        }
    }

    const getPlanColumns = () => plans.map((p, i) => (
        <PlanColumn 
            key={i}
            buttonType={getButtonType(p)}
            onUpgradeClicked={() => onUpgradeClicked(p)}
            planName={p.name}
            price={p.price_monthly}
            maxRules={p.rules}
            maxPages={p.pages}
            features={getPlanFeatures(i)}
        />
    ))

    return (
        <>
            {isLoading ? <div>Loading</div> :
            <>
            <Center>
                <Box display="inline-block" mt="10" ml="auto" mr="auto">
                    <HStack>
                        <Heading as="h1" size="2xl">Plans & Pricing</Heading>
                        <Spacer />
                        <Box>
                            {paymentMethodUrl ? <Link href={paymentMethodUrl} _hover={undefined} isExternal><Button variant="outline">Change payment method</Button></Link> : ''}
                            {!isCurrentPlanFree() ? <ChatOrEmailUsPopover><Button variant="outline" ml="2">Downgrade / Cancel</Button></ChatOrEmailUsPopover> : ''}
                        </Box>
                    </HStack>
                    <Box border="1px" borderColor="gray.200" rounded="md" p={4} bg="white" mt={10}>
                        <HStack spacing={10} alignItems="stretch">

                            {getPlanColumns()}

                            <PlanColumn 
                                buttonType="contact_us"
                                planName="Scale"
                                price="Let's talk"
                                maxRules="∞"
                                maxPages="∞"
                                features={getPlanFeatures(4)}
                            />
                        </HStack>
                    </Box>
                </Box>
            </Center>
            </>
        }
        </>
    );
}