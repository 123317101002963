import { Button } from "@chakra-ui/button";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/modal";
import { useRef } from "react";


export default function DeleteRuleAlertDialog(props) {

    const cancelRef = useRef()

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={props.onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Rule
                    </AlertDialogHeader>

                    <AlertDialogBody>
                    Are you sure you want to delete rule: "{props.ruleName}"? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={props.onDelete} ml={3} isLoading={props.isSubmitting}>
                        Delete
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}