import axios from "axios";
import qs from "qs";
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export function getUserState() {
    return axios.get('/api/user/')
}

export function getAvailableDatabases() {
    return axios.get("/api/list-dbs/")
}

export function getDatabaseColumns(databaseId) {
    return axios.get("/api/list-columns/", {
        params: { db_id: databaseId }
    })
}

export function getEditRuleData(ruleId) {
    return axios.get(`/api/edit-rule/${ruleId}/`)
}

export function createRule(data) {
    return axios.post("/api/notification-rules/", data)
}

export function updateRule(ruleId, data) {
    return axios.put(`/api/notification-rules/${ruleId}/`, data)
}

export function registerUser(firstName, lastName, email, password) {
    return axios.post('/accounts/signup/', qs.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password1: password
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        }
    })
}

export function loginUser(email, password) {
    return axios.post('/accounts/login/', qs.stringify({
        login: email,
        password: password
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        }
    })
}

export function sendResetPasswordEmail(email) {
    return axios.post('/accounts/password/reset/', qs.stringify({
        email: email
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        }
    })
}

export function resetPasswordSetNewPassword(id, password1, password2) {
    return axios.post(`/accounts/password/reset/key/${id}-set-password/`, qs.stringify({
        password1: password1,
        password2: password2
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        }
    })
}

export function getRules() {
    return axios.get("/api/notification-rules/")
}

export function deleteRule(ruleId) {
    return axios.delete(`/api/notification-rules/${ruleId}/`)
}

export function updateRuleOnOff(ruleId, isOn) {
    return axios.post(`/api/update-rule-on-off/${ruleId}/`, {
        is_on: isOn
    })
}

export function sendEmailVerification() {
    return axios.post('api/send-email-verification')
}

export function testWebhook(data) {
    return axios.post("/api/test-webhook/", data)
}

export function getPlans() {
    return axios.get("/api/plans/")
}

export function upgradePlan(planId) {
    return axios.post("/api/upgrade-plan/", {
        plan_id: planId
    })
}

export function finishedCheckout(checkoutId) {
    return axios.post("/api/finish-checkout/", {
        checkout_id: checkoutId
    })
}

export function getCreatePageForm(actionDatabaseId, triggerDatabaseId) {
    return axios.get("/api/create-page/", {
        params: { 
            action_db_id: actionDatabaseId, 
            trigger_db_id: triggerDatabaseId
        }
    })
}

const getServerBaseUrl = () => {
    if (window.location.hostname === "localhost") {
        return "http://localhost:8000/"
    }
    return window.location.origin + "/";
}

const serverBaseUrl = getServerBaseUrl();

export const links = {
    logout: serverBaseUrl + "logout/",
    googleSignin: serverBaseUrl + "accounts/google/login/",
    connectNotion: serverBaseUrl + "accounts/notion/login/?process=connect&next=/",
    privacyPolicy: "https://www.thegist.so/privacy-policy",
    termsAndConditions: "https://www.thegist.so/terms-and-conditions",
}