import { Center, Container, Heading, Link } from "@chakra-ui/layout";
import { links } from "../../services/HttpClient";
import { DividerWithText } from "./DividerWithText";
import LoginForm from "./LoginForm";
import { Link as RouterLink } from "react-router-dom"
import { useEffect } from "react";
import { page, track } from "../../services/Segment";
import ContinueWithGoogle from "./ContinueWithGoogle";

export default function LoginContainer() {

    useEffect(() => {
        page('/login')
    }, [])

    return (
        <Container bg="white" mt={10} maxW="lg" p={8}>
            <Center><Heading as="h1" mb={6}>Log in</Heading></Center>
            <Center><Link onClick={() => track('google login click')} href={links.googleSignin} w="100%"><ContinueWithGoogle /></Link></Center>

            <DividerWithText mt={3} mb={4}>OR</DividerWithText>
            <LoginForm />
            <Center mt={4}><Link fontSize="sm" as={RouterLink} to="/reset-password">Forgot your password?</Link></Center>
        </Container>
    )
}