import { Box } from "@chakra-ui/layout";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import ConnectNotionWorkspace from "./ConnectNotionWorkspace";
import Dashboard from "./Dashboard";
import LoginContainer from "./LoginSignup/LoginContainer";
import SignupContainer from "./LoginSignup/SignupContainer";
import ResetPasswordContainer from "./LoginSignup/ResetPasswordContainer"
import ResetPasswordSetPasswordContainer from './LoginSignup/ResetPasswordSetPasswordContainer'
import ResetPasswordInvalidTokenContainer from './LoginSignup/ResetPasswordInvalidTokenContainer'
import NavBar from "./NavBar";
import NewRule from "./NewRule/NewRule";
import URLMessageToastPresenter from "./MainLayout/URLMessageToastPresenter";
import TrackPageViews from "./MainLayout/TrackPageViews";
import PricingPlans from "./PricingPlans/PricingPlans";

function MainLayout(props) {

  const getLoggedInRoutes = () => (
    <Switch>
      <Route path="/plans"><PricingPlans email={props.user.data.email} uuid={props.user.data.uuid} /></Route>
      {!props.user.data.notion_workspace ?
        <Route path="/"><ConnectNotionWorkspace /></Route>
      : (
        <Switch>
          <Route path="/new-rule"><NewRule /></Route>
          <Route path="/edit-rule/:id"><NewRule /></Route>
          <Route path="/plans"><PricingPlans email={props.user.data.email} uuid={props.user.data.uuid} /></Route>
          <Route path="/"><Dashboard /></Route>
        </Switch>
      )
      }
    </Switch>
  )

  return (
      <Box pb="10">
        <Router>
          <URLMessageToastPresenter />
          <NavBar user={props.user} />
          <TrackPageViews />
          <Switch>
            <Route path="/signup"><SignupContainer /></Route>
            <Route path="/login"><LoginContainer /></Route>
            <Route path="/reset-password/invalid-token/"><ResetPasswordInvalidTokenContainer /></Route>
            <Route path="/reset-password/set/:id"><ResetPasswordSetPasswordContainer /></Route>
            <Route path="/reset-password"><ResetPasswordContainer /></Route>

            {!props.user.isLoggedIn ? 
              <Route path="/"><Redirect to="/login" /></Route>
              : getLoggedInRoutes()
            }
          </Switch>
        </Router>
      </Box>
    );
}

export default MainLayout