export function page(name) {
  window.analytics.page(name)
}

export function track(name) {
  window.analytics.track(name)
}

export function identify(userId) {
  window.analytics.identify(userId)
}