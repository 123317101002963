import { Button } from "@chakra-ui/button";
import { Container, Box } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import React, { useCallback, useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { getRules } from "../services/HttpClient";
import { useErrorToast } from "../services/Toaster";
import NotificationRulesTable from "./NotificationRulesTable"
import { Alert, AlertTitle, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { CloseButton } from "@chakra-ui/close-button";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react"

function Dashboard() {

    const initialFocusRef = React.useRef()

    const [rules, setRules] = useState([]);
    const [rulesCapReached, setRulesCapReached] = useState(false);
    const showErrorToast = useErrorToast();
    const [upgradeBannerClosed, setUpgradeBannerClosed] = useState(false)

    const loadRules = useCallback(() => {
        getRules().then(res => {
            setRules(res.data.rules)
            setRulesCapReached(res.data.rules_cap_reached)
        }).catch(error => {
            showErrorToast('Unexpected error', 'There was an unexpected error while loading your data. Please refresh and try again.')
        })
    }, [showErrorToast])


    useEffect(() => {
        loadRules()
    }, [loadRules]);

    const onUpgradeBannerCloseClick = (e) => {
        setUpgradeBannerClosed(true)
    }

    const showUpgradeWarning = rulesCapReached && !upgradeBannerClosed


    return (
        <Container maxW="container.lg" mt="16">
            {rules.length > 0 ?
                <>
                    <Flex>
                        <Heading as="h1" size="xl">My rules</Heading>
                        <Spacer />
                        {!rulesCapReached ?
                            <Button as={RouterLink} to="/new-rule" colorScheme="pink">+ New rule</Button>
                            :
                            <Popover
                                isLazy
                                initialFocusRef={initialFocusRef}
                                placement="top-start"
                                closeOnBlur={true}
                                arrowShadowColor="pink"
                            >
                                <PopoverTrigger>
                                    <Button colorScheme="pink">+ New rule</Button>
                                </PopoverTrigger>
                                <PopoverContent color="gray.800" borderColor="gray.200">
                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                        Rules quota used up.
                                    </PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        To create more rules you'll need to upgrade your plan.
                                    </PopoverBody>
                                    <PopoverFooter
                                        border="0"
                                        d="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        pb={4}
                                    >
                                        <Button ref={initialFocusRef} as={RouterLink} to="/plans" marginEnd={8} colorScheme="pink">View plans</Button>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>
                        }
                    </Flex>
                    {showUpgradeWarning ?
                        <Alert mt={4} rounded="sm" status="warning" variant="subtle">
                            <AlertIcon />
                            <Box flex="1">
                                <AlertTitle>Rules quota used up.</AlertTitle>
                                <AlertDescription display="block">
                                    To create more rules you'll need to upgrade your plan.
                                </AlertDescription>
                            </Box>
                            <Button as={RouterLink} to="/plans" marginEnd={8} variant="outline" color="gray.800" borderColor="gray.500">View plans</Button>
                            <CloseButton onClick={onUpgradeBannerCloseClick} position="absolute" right="8px" top="8px" />
                        </Alert>
                        : <></>
                    }

                    <NotificationRulesTable 
                        rules={rules} 
                        onRuleDeleted={loadRules} 
                        onRuleToggled={loadRules} 
                        mt={10} />
                </>
                :
                <Center>
                    <VStack spacing="12" mt="20">
                        <Heading as="h1" size="2xl">No rules yet.</Heading>
                        <Button as={RouterLink} to="/new-rule" colorScheme="pink">Create a rule!</Button>
                    </VStack>
                </Center>
            }
        </Container>
    );
}

export default Dashboard;