import { Button } from "@chakra-ui/button";
import { CheckIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import ChatOrEmailUsPopover from "./ChatOrEmailUsPopover";

export default function PlanColumn({ 
    buttonType, onUpgradeClicked, planName, price, maxRules, maxPages, features
 }) {

    let buttonCTA;
    if (buttonType === "current") {
        buttonCTA = <Button isDisabled variant="outline" size="md" w={40}>Current</Button>
    } else if (buttonType === "contact_us") {
        buttonCTA = <ChatOrEmailUsPopover><Button variant="outline" size="md" w={40}>Contact us</Button></ChatOrEmailUsPopover>
    } else if (buttonType === "upgrade") {
        buttonCTA = <Button variant="outline" size="md" w={40} onClick={onUpgradeClicked}>Upgrade</Button>
    }

    const featureRows = features.map(f => (
        <HStack key={f}>
            <CheckIcon w={4} h={4} />
            <Text fontSize="md">{f}</Text>
        </HStack>
    ))

    const getPrice = () => {
        if (isNaN(price)) {
            return price
        } else if (price === 0) {
            return "$0"
        } else {
            return <>${price}<Heading size="sm" display="inline" as={Box}>/month</Heading></>
        }
    }

    return (
        <Box background={buttonType === "current" ? "pink.50" : "none"} p="4">
            <Box>
                <Heading as="h3" size="md" color="gray.600">{planName}</Heading>
                <Heading as="h2" size="xl" color="gray.800" mt="2">{getPrice()}</Heading>
            </Box>
            <Box mt="4">
                {buttonCTA}
            </Box>
            <Box textColor="gray.800" spacing={6} mt="8">
                <VStack spacing={2}>
                    <Flex w={40}>
                        <Text fontSize="md">Rules</Text>
                        <Spacer />
                        <Text fontSize="md" fontWeight="semibold">{maxRules}</Text>
                    </Flex>
                    <Flex w={40}>
                        <Text fontSize="md">Triggered<br/>Pages</Text>
                        <Spacer />
                        <Text fontSize="md" fontWeight="semibold">{maxPages}</Text>
                    </Flex>
                </VStack>
                <VStack alignItems="start" mt="6">
                    {featureRows}
                </VStack>
            </Box>
        </Box>
    )
}