import { useDisclosure } from "@chakra-ui/hooks";
import { Select } from "@chakra-ui/select";
import ConnectDatabaseModal from "./ConnectDatabaseModal";
import { Tooltip } from "@chakra-ui/react"
import { Box } from "@chakra-ui/layout";

export default function ChooseColumnDropdown({
    selectedDatabaseId, onChangedDatabase, notionDbs, isLoading,
    isWaitingForOtherInputs, waitingForOthersTooltipText }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const onChange = (e) => {
        if (e.target.value === "connect") {
            onOpen()
        } else {
            onChangedDatabase(e.target.value)
        }
    }

    let dbsOptions = notionDbs.map((db) =>
        <option key={db.id} value={db.id}>{db.title}</option>
    );
    return (
        <>
            <Tooltip
                openDelay={50}
                isDisabled={!isWaitingForOtherInputs || !waitingForOthersTooltipText}
                label={waitingForOthersTooltipText}>
                {/* This box is here because tooltips don't work on disabled components. */}
                <Box>
                    <Select
                        isRequired
                        disabled={isLoading || isWaitingForOtherInputs}
                        value={selectedDatabaseId}
                        onChange={onChange}
                        variant="flushed"
                        size="sm"
                        focusBorderColor="pink.500"
                        w="formInputWidth"
                    >
                        <option disabled value="" hidden>{isLoading ? "Loading..." : "Select a database"}</option>
                        {dbsOptions}
                        <option value="connect">+ Connect a database</option>
                    </Select>
                </Box>
            </Tooltip>

            <ConnectDatabaseModal isOpen={isOpen} onClose={onClose} />
        </>
    )
}