import { IconButton } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Spacer } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/layout";
import { MenuItem } from "@chakra-ui/menu";
import { MenuList } from "@chakra-ui/menu";
import { MenuButton } from "@chakra-ui/menu";
import { Menu } from "@chakra-ui/menu";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import React, { useState } from "react"
import { useHistory } from "react-router";
import { deleteRule, updateRuleOnOff } from "../services/HttpClient";
import { useErrorToast, useSuccessToast, useWarningToast } from "../services/Toaster";
import DeleteRuleAlertDialog from "./Dashboard/DeleteRuleAlertDialog";
import RuleBrokenErrorMessage from "./Dashboard/RuleBrokenErrorMessage";

function NotificationRulesTable({ rules, onRuleDeleted, onRuleToggled, ...rest} ) {

    let history = useHistory();
    const showErrorToast = useErrorToast();
    const successToast = useSuccessToast();
    const warningToast = useWarningToast();

    const [showDeleteRuleDialog, setShowDeleteRuleDialog] = useState(false);
    const [ruleToDelete, setRuleToDelete] = useState({})
    const [isDeleting, setIsDeleting] = useState(false);
    
    const onDeleteRuleClicked = (rule) => {
        setShowDeleteRuleDialog(true);
        setRuleToDelete(rule)
    }

    const closeDeleteDialog = () => {
        setShowDeleteRuleDialog(false);
        setRuleToDelete({})
    }

    const onDeleteConfirmed = () => {
        setIsDeleting(true)
        deleteRule(ruleToDelete.id).then(resp => {
            setIsDeleting(false)
            setShowDeleteRuleDialog(false)
            onRuleDeleted()
            successToast("Rule successfully deleted")
        }).catch(error => {
            setIsDeleting(false)
            showErrorToast('Unexpected error', 'There was an unexpected error while trying to delete your rule. Please try again.')
        })
    }

    const editRule = (rule) => {
        history.push(`/edit-rule/${rule.id}/`)
    }

    const renderMatchFilter = (matchFilter) => {
        if (matchFilter.type === 'text') {
            return matchFilter.value
        } else if ((matchFilter.type === 'select') || (matchFilter.type === 'multi_select')) {
            return matchFilter.value.name
        } else if (matchFilter.type === 'checkbox') {
            return <Checkbox disabled isChecked={matchFilter.value} colorScheme="pink" />
        }
        return ''
    }

    const onSwitchToggle = (ruleId, isOn) => {
        updateRuleOnOff(ruleId, isOn).then(result => {
            if (result.data.success) {
                onRuleToggled()
            } else {
                warningToast('Can\'t toggle rule', result.data.message)
            }
        }).catch(error => {
            showErrorToast('Unexpected error', 'There was an unexpected error while trying to toggle your rule. Please try again.')
        })
    }

    const rulesRows = rules.map(rule => {
        return (
            <Box key={rule.id} bg="white" w="100%" pt="8" pb="8" pr="9" pl="9" color="gray.700" boxShadow="base" rounded="sm">
                <HStack>
                    <Box>
                        <Heading as="h5" size="sm">{rule.name}</Heading>
                        <Box fontSize="xs" mt="2">
                            <Box as="span" color="gray.400">In </Box>
                            <Link href={rule.database_url} isExternal>{rule.database_name}</Link>
                            <Box as="span" color="gray.400"> when </Box>
                            {rule.column_name}
                            <Box as="span" color="gray.400"> matches </Box>
                            {renderMatchFilter(rule.match_filter)}
                        </Box>
                        {rule.is_broken ? <RuleBrokenErrorMessage rule={rule} mt="5" /> : ""}
                    </Box>
                    <Spacer />

                    {!rule.is_broken ?
                        <FormControl display="flex" alignItems="center" w="max-content">
                            <FormLabel mb="0">{rule.is_on ? "Active" : "Paused"}</FormLabel>
                              <Switch size="lg" isChecked={rule.is_on} onChange={e => onSwitchToggle(rule.id, e.target.checked)} 
                                colorScheme="pink"
                            />
                        </FormControl>
                        : <></>
                    }
                    
                    <Box flexShrink="0">
                        <Menu>
                            <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="ghost" >
                                Actions
                            </MenuButton>
                            <MenuList>
                                {!rule.is_broken ? <MenuItem onClick={(e) => editRule(rule)}>Edit</MenuItem> : ''}
                                <MenuItem onClick={(e) => onDeleteRuleClicked(rule)}>Delete</MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </HStack>
            </Box>
        )
    })

    return (
        <>
            <VStack spacing="4" {...rest}>
                {rulesRows}
            </VStack>
            <DeleteRuleAlertDialog 
                isOpen={showDeleteRuleDialog}
                onClose={closeDeleteDialog}
                onDelete={onDeleteConfirmed}
                ruleName={ruleToDelete.name}
                isSubmitting={isDeleting}
            />
        </>
    )
}


export default NotificationRulesTable;