import { Checkbox } from "@chakra-ui/checkbox"
import { Input } from "@chakra-ui/input"
import { Select } from "@chakra-ui/select"
import { HStack, Flex } from "@chakra-ui/layout"
import { FormControl } from "@chakra-ui/form-control";

export default function RuleMatcherDynamicInput({
    selectedColumn, value, onChange, isLoading, selectRefreshClick, isRefreshButtonLoading, selectValue
}) {
    const inputOptions = selectedColumn ? selectedColumn.input_options : null
    const columnType = selectedColumn ? selectedColumn.input_type : ""

    const options = inputOptions && inputOptions.map(o => <option key={o.id} value={o.id}>{o.name}</option>)

    const handleTextChange = (e) => {
        onChange(e.target.value)
    }

    const handleCheckboxChange = (e) => {
        onChange(e.target.checked)
    }

    const handleSelectChange = (e) => {
        const selectedOption = inputOptions.find(o => o.id === e.target.value)
        onChange(selectedOption)
    }

    let operator = "contains"
    let operatorDisabled = !columnType
    let matchInput

    if (columnType === 'text') {
        matchInput = <Input variant="flushed" size="sm" focusBorderColor="pink.500"
            disabled={isLoading} isRequired placeholder="Enter text to match" value={value} onChange={handleTextChange} />
    } else if (columnType === 'checkbox') {
        operator = "is"
        matchInput = (
            <Flex h={8} alignItems="center">
                <Checkbox disabled={isLoading} isChecked={value} value={value} onChange={handleCheckboxChange} colorScheme="pink" size="lg" />
            </Flex>)
    } else if ((columnType === 'select') || (columnType === 'multi_select')) {
        operator = "matches"
        matchInput = (
            <Select variant="flushed" size="sm" focusBorderColor="pink.500"
                disabled={isLoading} isRequired value={selectValue} onChange={handleSelectChange}>
                <option value="" disabled hidden>Select an option</option>
                {options}
            </Select>
        )
    }

    return <HStack>
        <Select variant="flushed" size="sm" focusBorderColor="pink.500" maxW={28}
            disabled={operatorDisabled}
        >
            <option value="">{operator}</option>
            <option disabled value="">more options - coming soon</option>
        </Select>
        {
            operatorDisabled
                ? <Input variant="flushed" size="sm" focusBorderColor="pink.500" disabled placeholder="Enter text to match" />
                :
                <FormControl>
                    {matchInput}
                </FormControl>
        }

    </HStack>
}