import { Button } from "@chakra-ui/button";
import { FormLabel } from "@chakra-ui/form-control";
import { FormControl } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Heading, Container, HStack, Box, Stack, VStack, Flex } from "@chakra-ui/layout";
import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router";
import { createRule, getAvailableDatabases, getDatabaseColumns, getEditRuleData, testWebhook, updateRule, getCreatePageForm } from "../../services/HttpClient";
import ChooseColumnDropdown from "./ChooseColumnDropdown";
import RuleMatcherDynamicInput from "./RuleMatcherDynamicInput";
import ChooseDatabaseDropdown from "./ChooseDatabaseDropdown";
import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { IoIosRefresh } from "react-icons/io"
import { BsBook } from "react-icons/bs"
import { Spacer } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { useErrorToast, useSuccessToast } from "../../services/Toaster";
import { BsLightning } from "react-icons/bs"
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/alert"
import { useDisclosure } from "@chakra-ui/hooks";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"
import RuleFormLoading from "./RuleFormLoading";
import { track } from "../../services/Segment";
import CreatePageForm from "./CreatePageForm";


function NewRule(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [isLoadingDatabases, setIsLoadingDatabases] = useState(true);
    const [isLoadingColumns, setLoadingColumns] = useState(false);

    const [notionDbs, setNotionDbs] = useState([]);
    const [triggerSelectedDatabaseId, setTriggerSelectedDatabaseId] = useState('');
    const [actionSelectedDatabaseId, setActionSelectedDatabaseId] = useState('');
    const [createPageForm, setCreatePageForm] = useState([]);
    const [createPageValues, setCreatePageValues] = useState({})
    const [isLoadingCreatePageForm, setLoadingCreatePageForm] = useState(false);
    const [createPageFormError, setCreatePageFormError] = useState('')

    const [columns, setColumns] = useState([]);
    const [selectedColumnId, setSelectedColumnId] = useState('');

    const [matchFilter, setMatchFilter] = useState({});
    const [ruleName, setRuleName] = useState('');
    const [actionType, setActionType] = useState('')
    const [webhookURL, setWebhookURL] = useState('')
    const [webhookTestResult, setWebhookTestResult] = useState({})
    const [isSendingWebhookTestData, setIsSendingWebhookTestData] = useState(false)

    const [isColumnRefreshIconLoading, setColumnRefreshIconLoading] = useState(false);
    const [isMatchDropdownRefreshIsLoading, setMatchDropdownRefreshIsLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false)

    const errorToast = useErrorToast()
    const successToast = useSuccessToast()

    let history = useHistory();
    let { id } = useParams();

    const refreshDatabases = useCallback(() => {
        setIsLoadingDatabases(true);

        getAvailableDatabases().then(res => {
            setNotionDbs(res.data)
            setIsLoadingDatabases(false);
            setIsLoadingPage(false)
        }).catch(error => {
            errorToast('Error connecting with Notion', 'There was an unexpected error while looking for your connected Notion databases. Please refresh to retry.')
        })
    }, [errorToast])

    useEffect(() => {
        if (id) {
            getEditRuleData(id)
                .then(res => {
                    setIsLoadingPage(false)

                    setRuleName(res.data.name)
                    setMatchFilter(res.data.match_filter)

                    setNotionDbs(res.data.databases)
                    setTriggerSelectedDatabaseId(res.data.database_id)

                    setColumns(res.data.columns)
                    setSelectedColumnId(res.data.column_id)

                    setIsLoadingDatabases(false);

                    setActionType(res.data.action_type)
                    setWebhookURL(res.data.webhook_url)

                    setActionSelectedDatabaseId(res.data.create_page_values.parent_database_id)
                    setCreatePageValues(res.data.create_page_values.properties)
                    setCreatePageForm(res.data.create_page_form)
                }).catch(error => {
                    errorToast('Error connecting with Notion', 'There was an unexpected error while looking for your connected Notion databases. Please refresh to retry.')
                })
        } else {
            refreshDatabases()
        }

    }, [id, refreshDatabases, errorToast]);

    const onRefreshColumnsClicked = () => {
        setColumnRefreshIconLoading(true);
        refreshColumns(() => setColumnRefreshIconLoading(false));
    }


    const matchDropdownRefreshClicked = (e) => {
        setMatchDropdownRefreshIsLoading(true);
        refreshColumns(() => { setMatchDropdownRefreshIsLoading(false) })
    }

    const refreshColumns = (callback) => {
        setLoadingColumns(true)
        getDatabaseColumns(triggerSelectedDatabaseId).then(res => {
            setColumns(res.data)
            setLoadingColumns(false)
            callback()
        }).catch(error => {
            errorToast('Error connecting with Notion', 'There was an unexpected error while fetching the database details. Please refresh and try again.')
        })
    }

    const setDatabaseIdAndRefreshColumns = (databaseId) => {
        setTriggerSelectedDatabaseId(databaseId)
        setLoadingColumns(true)
        setColumnRefreshIconLoading(true)
        setSelectedColumnId('')
        getDatabaseColumns(databaseId).then(res => {
            setColumns(res.data)
            setLoadingColumns(false)
            setColumnRefreshIconLoading(false)
        }).catch(error => {
            errorToast('Error connecting with Notion', 'There was an unexpected error while fetching the database details. Please refresh and try again.')
        })
    }

    const onChangedTriggerDatabase = (databaseId) => {
        setDatabaseIdAndRefreshColumns(databaseId)
    }

    const onChangedActionDatabase = (databaseId) => {
        setActionSelectedDatabaseId(databaseId)
        setCreatePageFormError('')     
        setLoadingCreatePageForm(true)

        getCreatePageForm(databaseId, triggerSelectedDatabaseId).then(res => {
            setLoadingCreatePageForm(false)
            setCreatePageForm(res.data)
        }).catch(error => {
            setLoadingCreatePageForm(false)
            setCreatePageFormError(error)
        })
    }

    const onCreatePageValuesChange = (newValues) => {
        setCreatePageValues(newValues)
    }

    const onActionTypeChange = (e) => {
        setActionType(e.target.value)
    }

    const onWebhookURLChange = (e) => {
        setWebhookURL(e.target.value)
    }

    const shouldDisableSubmit = isSubmitting ||
        !ruleName ||
        matchFilter === {} || (matchFilter.value == null || matchFilter.value === '') ||
        actionType === '' ||
        (actionType === 'webhook' && webhookURL === '') ||
        (actionType === 'create_page' && (!actionSelectedDatabaseId || isLoadingCreatePageForm))

    const prepareData = () => {
        const databaseName = notionDbs.find(db => db.id === triggerSelectedDatabaseId).title;

        const create_page_values = {
            parent_database_id: actionSelectedDatabaseId,
            properties: createPageValues
        }

        return {
            'name': ruleName,
            'database_id': triggerSelectedDatabaseId,
            'database_name': databaseName,
            'column_id': selectedColumn.id,
            'column_name': selectedColumn.name,
            'match_filter': matchFilter,
            'action_type': actionType,
            'webhook_url': webhookURL,
            'create_page_values': create_page_values
        }
    }

    const onSendTestDataClick = (e) => {
        e.preventDefault();
        setWebhookTestResult({})
        if (!e.target.form.reportValidity()) {
            console.log("Can't test because the form isn't valid")
            return
        }

        setIsSendingWebhookTestData(true)
        const data = prepareData()

        track("Test webhook clicked")
        testWebhook(data)
            .then(res => {
                setIsSendingWebhookTestData(false)

                const success = res.data.success
                const pageSent = res.data.page_sent
                let title = ""
                let message = ""
                let moreInfo = ""
                if (success) {
                    if (pageSent === 'no_filter') {
                        title = "Sent a page that doesn't match your filter."
                        message = "Didn't find a matching page, sent a random page instead. You can create a matching page and try again."
                    } else {
                        title = "Success!"
                        message = "Test data sent, check your destination."
                    }
                } else {
                    if (pageSent === 'not_found') {
                        title = "Your database is empty."
                        message = "We rely on existing page for test data. Please create a page and try again."
                    } else {
                        message = "Failed to send test data."
                        moreInfo = res.data.error_message
                    }
                }

                const result = {
                    success: success,
                    pageSent: pageSent,
                    title: title,
                    message: message,
                    moreInfo: moreInfo
                }
                setWebhookTestResult(result)
            }).catch(error => {
                setIsSendingWebhookTestData(false)
                setWebhookTestResult({
                    success: false,
                    title: "Got an unexpected error.",
                    message: "Please try again or contact us."
                })
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        const data = prepareData()

        if (id) {
            track("Edit rule clicked")
            updateRule(id, data)
                .then(res => {
                    setIsSubmitting(false)
                    successToast('Rule successfully edited')
                    history.push('/')
                    track("Edit rule success")
                }).catch(error => {
                    setIsSubmitting(false)
                    errorToast("Unexpected error", "There was an unexpected error while updating your rule. Please try again.", 5000)
                    track("Edit rule error")
                })
        } else {
            track("Create rule clicked")
            createRule(data)
                .then(res => {
                    setIsSubmitting(false)
                    successToast('Rule successfully created')
                    history.push('/')
                    track("Create rule success")
                }).catch(error => {
                    setIsSubmitting(false)
                    errorToast("Unexpected error", "There was an unexpected error while creating your rule. Please try again.", 5000)
                    track("Create rule error")
                })
        }
    }

    const selectedColumn = columns.find(c => c.id === selectedColumnId);

    const onColumnChosen = (columnId) => {
        setSelectedColumnId(columnId)
        const column = columns.find(c => c.id === columnId);

        if (column.input_type === 'checkbox') {
            setMatchFilter({ type: column.input_type, value: false })
        } else {
            setMatchFilter({ type: column.input_type, value: '' })
        }
    }

    let databaseUrl = '';
    if (triggerSelectedDatabaseId) {
        databaseUrl = notionDbs.find(db => db.id === triggerSelectedDatabaseId).url;
    }

    if (isLoadingPage) {
        return <RuleFormLoading />
    }

    return (
        <Container maxW="container.sm" border="1px" borderColor="gray.200" rounded="md" p="12" bg="white" mt="10">
            <Flex alignItems="baseline" mb="12">
                <Heading size="xl">{id ? "Edit Rule" : "Create a new rule"}</Heading>
                <Spacer />
                <Link fontWeight="bold" color="pink.500" isExternal href="https://www.notion.so/Notion-Push-Examples-fd73ae6ef2294d8c8505cf9d0c8b0bb0">
                    <Flex alignItems="center"><Icon as={BsBook} mr={2}></Icon>Browse examples</Flex>
                </Link>
            </Flex>
            <form onSubmit={handleSubmit}>
                <VStack spacing={8}>
                    {/* Name input */}
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={ruleName} autoComplete="off" isRequired placeholder="Enter rule name"
                            focusBorderColor="pink.500"
                            onChange={e => setRuleName(e.target.value)} />
                    </FormControl>
                    {/* Rule config inputs */}
                    <Stack spacing={8} w="100%">
                        <FormControl>
                            <Flex alignItems="center">
                                <HStack spacing={4}>
                                    <Heading size="md" w={12}>In</Heading>
                                    <HStack>
                                        <ChooseDatabaseDropdown
                                            isLoading={isLoadingDatabases}
                                            selectedDatabaseId={triggerSelectedDatabaseId}
                                            onChangedDatabase={onChangedTriggerDatabase}
                                            notionDbs={notionDbs}
                                            isWaitingForOtherInputs={false}
                                        />
                                    </HStack>
                                </HStack>
                                <Spacer />
                                <IconButton variant="ghost" size="sm" icon={<Icon as={IoIosRefresh} />}
                                    onClick={refreshDatabases} isLoading={isLoadingDatabases}
                                />
                            </Flex>
                        </FormControl>
                        <Flex alignItems="center">
                            <HStack spacing={4}>
                                <Heading size="md" w={12}>if</Heading>
                                <HStack>
                                    <ChooseColumnDropdown
                                        disabled={triggerSelectedDatabaseId === ''}
                                        columns={columns}
                                        isLoading={isLoadingColumns}
                                        selectedColumnId={selectedColumnId}
                                        onChange={onColumnChosen}
                                        databaseUrl={databaseUrl}
                                    />
                                    <RuleMatcherDynamicInput
                                        isLoading={isLoadingColumns}
                                        selectedColumn={selectedColumn}
                                        value={matchFilter.value}
                                        selectValue={matchFilter.value ? matchFilter.value.id : ''}
                                        onChange={v => setMatchFilter({ type: selectedColumn.input_type, value: v })}
                                        selectRefreshClick={matchDropdownRefreshClicked}
                                        isRefreshButtonLoading={isMatchDropdownRefreshIsLoading}
                                    />
                                </HStack>
                            </HStack>
                            <Spacer />
                            <IconButton variant="ghost" size="sm" icon={<Icon as={IoIosRefresh} />}
                                onClick={onRefreshColumnsClicked}
                                isLoading={isColumnRefreshIconLoading}
                                disabled={triggerSelectedDatabaseId === ''}
                            />
                        </Flex>
                        <HStack spacing={4} alignItems="center" w="100%">
                            <Heading size="md" minW={12} w={12}>then</Heading>
                            <HStack spacing={4} w="100%">
                                <FormControl isRequired w="formInputWidth">
                                    <Select value={actionType} onChange={onActionTypeChange} w="formInputWidth" variant="flushed" size="sm" focusBorderColor="pink.500"
                                        placeholder="Select an action"
                                    >
                                        <option value="send_email">email me</option>
                                        <option value="webhook">call a webhook</option>
                                        <option value="create_page">create a page</option>
                                        <option disabled value="slack">slack - coming soon</option>
                                    </Select>
                                </FormControl>
                                {actionType === "send_email" ?
                                    <FormControl>
                                        <Select w="formInputWidth" variant="flushed" size="sm" focusBorderColor="pink.500">
                                            <option value="realtime">when it happens</option>
                                            <option disabled value="digest">digests - coming soon</option>
                                        </Select>
                                    </FormControl>
                                    :
                                    actionType === "create_page" ?
                                        <Flex alignItems="center" w="100%">
                                            <HStack spacing={4}>
                                                <Heading size="md">in</Heading>
                                                <HStack>
                                                    <ChooseDatabaseDropdown
                                                        isLoading={isLoadingDatabases}
                                                        selectedDatabaseId={actionSelectedDatabaseId}
                                                        onChangedDatabase={onChangedActionDatabase}
                                                        notionDbs={notionDbs}
                                                        isWaitingForOtherInputs={!triggerSelectedDatabaseId}
                                                        waitingForOthersTooltipText='Select the trigger database first.'
                                                    />
                                                </HStack>
                                            </HStack>
                                            <Spacer />
                                            <IconButton variant="ghost" size="sm" icon={<Icon as={IoIosRefresh} />}
                                                onClick={refreshDatabases} isLoading={isLoadingDatabases}
                                            />
                                        </Flex>
                                        : <></>}
                            </HStack>
                        </HStack>
                        {actionType === "create_page" ?
                            <CreatePageForm
                                formData={createPageForm}
                                formValues={createPageValues}
                                onValuesChange={onCreatePageValuesChange}
                                isLoading={isLoadingCreatePageForm}
                                errorLoadingForm={createPageFormError} />

                            : actionType === "webhook" ?
                                <HStack width="100%" spacing={4} alignItems="center">
                                    <Box minW={12} />
                                    <VStack width="100%" spacing={4}>
                                        <Flex alignItems="flex-start" w="100%" justifyContent="space-between">
                                            <FormControl isRequired flexGrow="2" flexShrink="3">
                                                <Input value={webhookURL} type="url" onChange={onWebhookURLChange} variant="flushed" size="sm" placeholder="Paste webhook URL" focusBorderColor="pink.500" />
                                            </FormControl>
                                            <Spacer />
                                            <Button onClick={onSendTestDataClick} name="testWebhook" type="submit" h={8} w={32} colorScheme="gray"
                                                isLoading={isSendingWebhookTestData} loadingText="Sending" spinnerPlacement="start"
                                                variant="ghost" marginStart={2} flexGrow="3" size="xs" leftIcon={<Icon as={BsLightning} />}>Send test data</Button>
                                        </Flex>
                                        {webhookTestResult && Object.keys(webhookTestResult).length > 0 ?
                                            <Alert status={webhookTestResult.success ? "success" : "warning"}>
                                                <AlertIcon />
                                                <Box flex="1">
                                                    <AlertTitle>{webhookTestResult.title}</AlertTitle>
                                                    <AlertDescription display="block">
                                                        {webhookTestResult.message}{webhookTestResult.moreInfo ? <Button onClick={onOpen} size="sm" marginStart={2} variant="link">More info</Button> : ""}
                                                    </AlertDescription>
                                                </Box>
                                            </Alert>
                                            : <></>
                                        }
                                        <Modal isOpen={isOpen} onClose={onClose}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>More Info</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody>
                                                    {webhookTestResult.moreInfo}
                                                </ModalBody>

                                                <ModalFooter>
                                                    <Button colorScheme="pink" mr={3} onClick={onClose}>
                                                        Close
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                    </VStack>
                                </HStack>
                                : <></>}
                    </Stack>
                    {/* Buttons */}
                    <HStack spacing={8} w="100%">
                        <Button isDisabled={isSubmitting} w="100%" variant="outline"
                            onClick={() => history.goBack()}>Cancel</Button>
                        <Button name="submit" isLoading={isSubmitting} isDisabled={shouldDisableSubmit}
                            w="100%" colorScheme="pink" type="submit">{id ? "Update" : "Create"}</Button>
                    </HStack>
                </VStack>
            </form>
        </Container>
    )
}

export default NewRule;