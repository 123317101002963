import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Tooltip } from "@chakra-ui/tooltip";
import { links } from "../services/HttpClient";
import { ReactComponent as Logo } from "../imgs/app_icon.svg"
import { Link as RouterLink } from "react-router-dom"
import EmailVerificationAlert from "./NavBar/EmailVerificationAlert";
import { Heading, HStack } from "@chakra-ui/layout";
import { LinkBox } from "@chakra-ui/layout";
import { LinkOverlay } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Menu } from "@chakra-ui/menu";
import { MenuButton } from "@chakra-ui/menu";
import { MenuList, MenuItem, MenuDivider } from "@chakra-ui/menu";
import { Text } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import Icon from "@chakra-ui/icon";
import { MdPayment } from "react-icons/md"
import { IoLogOutOutline } from "react-icons/io5"



function NavBar(props) {

    let content;

    if (props.user.isLoggedIn) {
        const name = props.user.data.first_name + " " + props.user.data.last_name
        content = (
            <>
                {props.user.data.notion_workspace ?
                    <Tooltip label="Multiple workspaces coming soon!">
                        <Box>
                            <Select w="auto" bg="white" isDisabled placeholder={props.user.data.notion_workspace.name} />
                        </Box>
                    </Tooltip>
                    : <></>}
                <Spacer />
                <Menu autoSelect={false}>
                    <MenuButton>
                        <Avatar name={name} size="sm" color="white" background="gray.900" />
                    </MenuButton>
                    <MenuList>
                        <VStack alignItems="start" paddingStart={3} paddingEnd={3}>
                            <Text fontSize="2xl">{name}</Text>
                            <Text fontSize="md" color="gray.500">{props.user.data.email}</Text>
                        </VStack>
                        <MenuDivider />
                        <Link as={RouterLink} to="/plans" _hover={undefined}>
                            <MenuItem>
                                <HStack alignItems="center">
                                    <Icon as={MdPayment} />
                                    <Text>Plans</Text>
                                </HStack>
                            </MenuItem>
                        </Link>
                        <Link href={links.logout} _hover={undefined}>
                            <MenuItem>
                                <HStack alignItems="center">
                                    <Icon as={IoLogOutOutline} />
                                    <Text>Logout</Text>
                                </HStack>
                            </MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
            </>
        )
    } else {
        content = (
            <>
                <Spacer />
                <Link as={RouterLink} to="/login" fontWeight="bold">Log in</Link>
                <Link as={RouterLink} to="/signup" fontWeight="bold" ml={6} mr={10}>Sign up</Link>
            </>
        )
    }

    function showEmailVerificationAlert() {
        return props.user.isLoggedIn && !props.user.data.is_email_verified && props.user.data.notion_workspace
    }

    return (
        <>
            <Flex alignItems="center" bg="white" h="16" px={3}>
                <LinkBox w="48">
                    <HStack w="48" alignItems="center">
                        <Logo height="36px" width="36px" />
                        <LinkOverlay href={props.user.isLoggedIn ? "/" : "https://thegist.so/"}>
                            <Heading color="black" size="md">the:gist</Heading>
                        </LinkOverlay>
                    </HStack>
                </LinkBox>
                {content}
            </Flex>
            {showEmailVerificationAlert() ? <EmailVerificationAlert /> : ''}
        </>
    )
}

export default NavBar;