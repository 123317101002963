import { Box, Heading } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Container } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/layout";
import { links } from "../../services/HttpClient";
import { DividerWithText } from "./DividerWithText";
import { SignupForm } from "./SignupForm";
import { useEffect } from "react";
import { page, track } from "../../services/Segment";
import ContinueWithGoogle from "./ContinueWithGoogle";

export default function SignupContainer() {

    useEffect(() => {
        page('/signup')
    }, [])

    return (
        <Container bg="white" mt={10} maxW="lg" p={8}>
            <Center><Heading as="h1" mb={6}>Sign up</Heading></Center>
            <Center>
                <Link onClick={() => track('google signup click')} href={links.googleSignin} w="100%">
                    <ContinueWithGoogle />
                </Link>
            </Center>

            <DividerWithText mt={3} mb={4}>OR</DividerWithText>
            <SignupForm />
            <Box mt={6} textAlign="center" fontSize="sm">By clicking "Continue with Google" or "Create my account" above, 
                you acknowledge that you have read and understood, and agree to
                our <Link onClick={() => track('terms click')} href={links.termsAndConditions} textDecor="underline">Terms of Service</Link> and <Link onClick={() => track('privacy click')} href={links.privacyPolicy} textDecor="underline">Privacy Policy</Link>.
            </Box>
        </Container>
    )
}